<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CButton @click="collapse=!collapse" color="primary" class="mb-2 mr-2">
                    Filter
                </CButton>
                <CCollapse :show="collapse" :duration="400">
                    <CCard>
                        <CCardHeader>
                            <strong>Filter Options</strong>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.id.val" :description="form.id.inv" label="ID" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <label>Created at</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.created_at.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.created_at.inv}}</small>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label>Updated at</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.updated_at.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.updated_at.inv}}</small>
                                </CCol>
                            </CRow>
                            <br>
                            <div class="form-group form-actions">
                                <CButton @click="validator();filter=true;page=1;count();get();" color="primary" class="mr-2">Filter</CButton>
                                <CButton @click="remove_filter" color="danger">Remove Filter</CButton>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCollapse>
            </CCol>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>User Groups</strong>
                        <div class="card-header-actions">
                            Number of All Rows: {{row_count}}
                        </div>
                        </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }">
                            <template #is_active="data">
                                <td>
                                    <CBadge :color="get_badge(data.item.is_active)">
                                        {{data.item.is_active}}
                                    </CBadge>
                                </td>
                            </template>
                            <template #action="data">
                                <td>
                                    <template>
                                    <div class="flex-container">
                                        <div v-show="$store.state.perm['system.UsersToUserGroup'].post"
                                            @click="redirect_to(`/group/${data.item.id}/user/add`)"
											v-c-tooltip.hover="'Add User'">
											<CIcon height="20" :content="$options.add"/>
										</div>
										<div v-show="$store.state.perm['system.UsersToUserGroup'].get"
                                            @click="redirect_to(`/group/${data.item.id}/users`)"
											v-c-tooltip.hover="'Group Users'">
											<CIcon height="20" :content="$options.details"/>
										</div>
                                        <div @click="modal=true;id=data.item.id;get_perms();"
											v-c-tooltip.hover="'Permissions'">
											<CIcon height="20" :content="$options.permissions"/>
										</div>
                                        <div v-show="$store.state.perm['system.UserGroup'].put"
                                            @click="redirect_to(`/group/${data.item.id}/update`)"
                                            v-c-tooltip.hover="'Update'">
                                        <CIcon height="20" :content="$options.update"/>
                                        </div>
                                        <div v-show="$store.state.perm['system.UserGroup'].delete"
                                            @click="delete_modal=true;id=data.item.id"
                                            v-c-tooltip.hover="'Delete'">
                                        <CIcon height="20" :content="$options.delete"/>
                                        </div>
                                    </div>
                                    </template>
                                </td>
                            </template>
                        </CDataTable>
                            <br>
                            <div> 
                                <CPagination
                                :activePage.sync="page"
                                :pages="page_count"
                                align="center"
                                :doubleArrows="true"
                                />
                            </div>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            title="Alert"
            size="sm"
            color="danger"
            :show.sync="delete_modal"
            >
            <p>Are you sure?</p>
            <template #footer>
                <CButton @click="delete_modal=false" color="secondary">No</CButton>
                <CButton @click="delete_row" color="danger">Yes</CButton>
            </template>
        </CModal>
        <CModal
            title="Permissions"
            size="lg"
            color="primary"
            :show.sync="modal"
            >
            <CTabs variant="pills">
                <CTab title="Methods" active>
                    <br>
                    <CRow>
                        <CCol sm="12">
                            <CDataTable hover striped :items="modules_items" :fields="modules_fields"
                                :noItemsView="{ noItems: 'No items to show.' }">
                                <template #action="data">
                                <td>
                                    <template>
                                        <div class="flex-container">
                                            <div @click="get_methods();module_name=data.item.module;methods_modal=true"
                                                v-c-tooltip.hover="'Methods'">
                                            <CIcon height="20" :content="$options.permissions"/>
                                            </div>
                                        </div>
                                    </template>
                                </td>
                            </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CTab>
                <CTab title="Dashboard">
                    <br>
                    <CRow>
                        <CCol sm="12">
                            <CDataTable hover striped :items="perms_items" :fields="perms_fields"
                                :noItemsView="{ noItems: 'No items to show.' }">
                                <template #include="data">
                                    <td>
                                        <input type="radio" :name="data.item.id" :value="`i-${data.item.id}`"
                                            :id="`i-${data.item.id}`" class="form-check-input" >
                                    </td>
                                </template>
                                <template #except="data">
                                    <td>
                                        <input type="radio" :name="data.item.id" :value="`e-${data.item.id}`"
                                            :id="`e-${data.item.id}`" class="form-check-input" >
                                    </td>
                                </template>
                                <template #none="data">
                                    <td>
                                        <input type="radio" :name="data.item.id" :value="`n-${data.item.id}`"
                                            :id="`n-${data.item.id}`" class="form-check-input" >
                                    </td>
                                </template>
                            </CDataTable>
                            <br>
                            <div role="group" class="form-check">
                                <strong>
                                    <label>
                                        <input id="limit_with_include_field_values" type="checkbox" class="form-check-input">
                                        Limit with Include Field Values
                                    </label>
                                </strong>
                            </div>
                            <br>
                            <div class="form-group form-actions">
                                <CButton @click="submit_perms" color="primary" class="mr-2">Submit</CButton>
                                <CButton @click="delete_perms" color="danger">Delete Field</CButton>
                            </div>
                        </CCol>
                    </CRow>
                </CTab>
            </CTabs>
            <template #footer>
                <CButton @click="modal=false" color="secondary">Close</CButton>
            </template>
        </CModal>
        <CModal
            title="Methods"
            size="sm"
            color="info"
            :show.sync="methods_modal"
            >
            <CForm @keyup.enter="submit_methods">
                <CRow>
                    <CCol sm="12">
                        <CInputCheckbox v-for="option in perm_array" v-model="option.value"
                            true-value="true" false-value="false" :key="option.label"
                            :label="option.label" :checked="option.value === true"
                            @change="option.value = $event.target.checked"
                        />
                    </CCol>
                </CRow>
            </CForm>
            <template #footer>
                <CButton @click="submit_methods" color="primary">submit</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { cilPlaylistAdd, cilListRich, cilPencil, cilTrash, cilGrid } from '@coreui/icons'
export default {
    add: cilPlaylistAdd, details: cilListRich, update: cilPencil, delete: cilTrash,
    permissions: cilGrid,
    data () {
        return {
            row_count: '',
            items: [],
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'created_at', label: 'Created at' },
                { key: 'updated_at', label: 'Updated at' },
                { key: 'is_active', label: 'State' },
                { key: 'action', label: 'Action' },
            ],
            collapse: false,
            filter: false,
            form: {
                id: {val: '', inv: '', v: { max: 1024 }},
                name: {val: '', inv: '', v: { max: 1024 }},
                is_active: {val: '', inv: '', v: { max: 1024 }},
                created_at: {val: '', inv: '', v: { max: 1024 }},
                updated_at: {val: '', inv: '', v: { max: 1024 }},
            },
            disable: false,
            page: 1,
            page_count: 0,
            offset: 0,
            delete_modal: false,
            id: 0,
            modal: false,
            modules_items:[
                {name: 'Users', module:'system.User', url: '/system/users/'},
                {name: 'User Groups', module:'system.UserGroup', url: '/system/user_groups/'},
                {name: 'User to User Group', module:'system.UsersToUserGroup', url: '/system/users_to_user_groups/'},
                {name: 'Tokens', module:'system.GuestUserTokens', url: '/system/guest_user_tokens/'},
                {name: 'Data Sources', module:'arbitrage.DataSources', url: '/arbitrage/data_sources/'},
                {name: 'Data Sources Logs', module:'arbitrage.DataSourceLogs', url: '/arbitrage/data_source_logs/'},
                {name: 'Input Data', module:'arbitrage.InputDatas', url: '/arbitrage/input_datas/'},
                {name: 'Specific Data', module:'arbitrage.SystemCustomValues', url: '/arbitrage/system_custom_values/'},
                {name: 'Processes', module:'arbitrage.InputProccess', url: '/arbitrage/input_proccesses/'},
                {name: 'Output Data', module:'arbitrage.OutputDatas', url: '/arbitrage/output_datas/'},
                {name: 'Triggers', module:'arbitrage.InputProccessTriggers', url: '/arbitrage/input_proccess_triggers/'},
                {name: 'Telegram Accounts', module:'system.TelegramAccounts', url: '/system/telegram_accounts/'},
            ],
            modules_fields: [
                { key: 'name', label: 'Name' },
                { key: 'action', label: 'Set Permissions' },
            ],
            perm_array: {
                get_action: {label: "Get Data", value: false},
                post_action: {label: "Post Data", value: false},
                put_action: {label: "Update Data", value: false},
                delete_action: {label: "Delete Data", value: false},
            },
            methods_modal: false,
            module_name: '',
            perms_fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'include', label: 'Include Field Values' },
                { key: 'except', label: 'Except Field Values' },
                { key: 'none', label: 'None' },
            ],
            perms_items: [],
        }
    },
    watch: {
        page: {
            handler(){
                this.count();
                this.get();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        modal: {
            handler(){
                if(this.modal == false){
                    this.perms_items = [];
                }
            }
        }
    },
    mounted(){
        this.count();
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        count() {
            if(this.disable == false) {
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('system/user_groups/?count=1' + filter)
                    .then(res => {
                        this.row_count = String(res.data.count).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        if(res.data.count > 100){
                            this.page_count = Math.ceil(res.data.count / 100);
                        } else {
                            this.page_count = 1;
                        }
                    })
            }
        },
        get() {
            if (this.disable == false) {
                this.offset = 0;
                if(this.page != 1){
                    this.offset = (this.page * 100) - 99;
                }
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('system/user_groups/' 
                    + '?limit=100' 
                    + '&offset=' + this.offset
                    + filter)
                    .then(res => {
                        res.data.map(val => {
                            switch(val.is_active){
                                case true:
                                    val.is_active = 'Active';
                                    break;
                                case false:
                                    val.is_active = 'Inactive';
                                    break;
                            }
                            val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                            val.updated_at = this.$moment(val.updated_at).format('YYYY-MM-DD HH:mm:ss');
                        });
                        this.items = res.data;
                    })
            }
        },
        delete_row() {
			this.axios.delete('system/user_groups/'
			+ '?id=' + this.id)
			.then(() => {
				this.$notify({
					group: 'foo',
					type: 'success',
					text: 'Deleted Successfully.'
                });
				this.delete_modal = false;
                this.count();
				this.get();
			})
			.catch(() => {
				this.$notify({
					group: 'foo',
					type: 'error',
					text: 'Error! please try again.'
                });
                this.delete_modal = false;
			})
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        get_badge(status) {
            switch (status) {
                case 'Active': return 'success'
                case 'Inactive': return 'danger'
                default: 'secondary'
            }
        },
        remove_filter() {
            for (const key in this.form) {
                this.form[key].val = '';
                this.form[key].inv = '';
            }
            this.disable = false;
            this.filter = false;
            this.page = 1;
            this.count();
            this.get();
        },
        get_methods(){
            this.axios.get('system/user_groups/'
                + '?id=' + this.id
                + '&perm_array=1')
                .then(res => {
                    if(`${this.module_name}` in res.data[0].perm_array.ObjectPermissions){
                        for(const key in res.data[0].perm_array.ObjectPermissions[this.module_name].main_object){
                            for(const k in this.perm_array){
                                if(key == k){
                                    this.perm_array[k].value = res.data[0].perm_array.ObjectPermissions[this.module_name].main_object[k].can_do_action;
                                }
                            }
                        }
                    }
                })
                .finally(() => { this.modal1 = true; })
        },
        submit_methods(){
            Object.keys(this.perm_array).forEach((key) => {
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.id)]));
                data.set('object_type', 'main_object');
                data.set('action_type', key);
                data.set('module_type', this.module_name);
                data.set('each_action_type', 'can_do_action');
                data.set('can_do_action', this.perm_array[key].value);
                this.axios.put('system/user_groups/', data)
                .then(() => {
                    this.methods_modal = false;
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        text: `${key} Submitted Successfully.`
                    });
                })
            })
        },
        get_perms(){
            this.axios.get('arbitrage/input_proccesses/')
                .then(res => {
                    res.data.map(val => {
                        this.perms_items.push({id: val.id, name: val.name, value: ''});
                    });
                })
                .finally(() => {
                    this.axios.get('system/user_groups/'
                    + '?id=' + this.id
                    + '&perm_array=1')
                    .then(res => {
                        if(res.data[0].perm_array != null){
                            if('int_fields' in res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action){
                                const include_field_values = res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action.int_fields.id.include_field_values;
                                const except_field_values = res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action.int_fields.id.except_field_values;
                                const limit_with_include_field_values = res.data[0].perm_array.ObjectPermissions['arbitrage.InputProccess'].main_object.get_action.int_fields.id.limit_with_include_field_values;
                                for(const i in include_field_values){
                                    for(const k in this.perms_items){
                                        if(include_field_values[i] == this.perms_items[k].id){
                                            this.perms_items[k].value = `i-${this.perms_items[k].id}`;
                                            document.getElementById(`i-${this.perms_items[k].id}`).checked = true;
                                        }
                                    }
                                }
                                for(const i in except_field_values){
                                    for(const k in this.perms_items){
                                        if(except_field_values[i] == this.perms_items[k].id){
                                            this.perms_items[k].value = `e-${this.perms_items[k].id}`;
                                            document.getElementById(`e-${this.perms_items[k].id}`).checked = true;
                                        }
                                    }
                                }
                                if(limit_with_include_field_values){
                                    document.getElementById('limit_with_include_field_values').checked = true;
                                }
                            }
                        }
                        this.perms_items.map(val => {
                            if(val.value == ''){
                                val.value = `n-${val.id}`;
                                document.getElementById(`n-${val.id}`).checked = true;
                            }
                        });
                    })
                })
        },
        submit_perms(){
            let include_ids = [];
            let except_ids = [];
            this.perms_items.map(val => {
                let value = document.querySelector(`input[name="${val.id}"]:checked`).value.split('-');
                switch(value[0]){
                    case 'i':
                        include_ids.push(parseInt(value[1]));
                        break;
                    case 'e':
                        except_ids.push(parseInt(value[1]));
                        break;
                }
            })
            const data = new FormData();
            data.set('ids', JSON.stringify([parseInt(this.id)]));
            data.set('object_type', 'main_object');
            data.set('action_type', 'get_action');
            data.set('module_type', 'arbitrage.InputProccess');
            data.set('each_action_type', 'update_field');
            data.set('field_name', 'id');
            data.set('include_field_values', JSON.stringify(include_ids));
            if(document.getElementById('limit_with_include_field_values').checked){
                data.set('limit_with_include_field_values', true);
            } else {
                data.set('limit_with_include_field_values', false);
            }
            data.set('except_field_values', JSON.stringify(except_ids))
            this.axios.put('system/user_groups/', data)
                .then(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        text: 'Submitted Successfully.'
                    });
                })
        },
        delete_perms(){
            const data = new FormData();
            data.set('ids', JSON.stringify([parseInt(this.id)]));
            data.set('object_type', 'main_object');
            data.set('action_type', 'get_action');
            data.set('module_type', 'arbitrage.InputProccess');
            data.set('each_action_type', 'delete_field');
            data.set('field_name', 'id');
            this.axios.put('system/user_groups/', data)
                .then(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        text: 'Submitted Successfully.'
                    });
                })
        }
    },
}
</script>
